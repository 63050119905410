import * as React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import NotFoundImage from "../images/home-banner-bg.jpg";
import IpTracker from "../components/utilities/ip-tracker";

/* @TODO Not needed and should be removed. Remove below in the JSX as well. */
const wrapper = {
  padding: "3rem",
  marginTop: "3rem",
};

const NotFoundPage = () => {
  return (
    <>
      <Helmet
        title="404 - Not found!"
        titleTemplate={`%s - Astroturf`}
        meta={[
          {
            name: `description`,
            content: "The page that you are looking for could not be found.",
          },
        ]}
      >
        <link
          href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          rel="stylesheet"
          href="https://use.typekit.net/esz4xad.css"
        ></link>
      </Helmet>

      <main>
        <section
          id="page-not-found-404"
          className="page-title section-title-with-background-image"
        >
          <img className="section-img" alt="404" src={NotFoundImage} />;
          <div className="container">
            <div className="sz wrapper">
              <div className="content-title-bg-image">
                <h1>404 </h1>
                <h1>Ball's Out of Bounds!</h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container mx-auto py-5" style={wrapper}>
          <p className="lead">
            Please use the main menu to get back in the game.
          </p>
          <Link className="btn btn-primary  btn-hover-icon" to="/">
            Go home
          </Link>
        </div>
      </main>
      <IpTracker />
    </>
  );
};

export default NotFoundPage;
